require('./bootstrap');
import Vue from 'vue'
window.Vue = require('vue');

import { gsap } from "gsap";

Vue.component('hero-component', require('./components/HeroComponent.vue').default);
//Vue.component('product-component', require('./components/ProductComponent.vue').default);
Vue.component('cart-component', require('./components/CartComponent.vue').default);
Vue.component('finder-component', require('./components/FinderComponent.vue').default);

const app = new Vue({
    el: '#app',
    data:{
        cartID:false,
        mobileNav: "closed"
    },
    components: {
        //'hero-component': HeroComponent
    },
    created: function(){
        this.test = this.getTest();
        //this.loadEvents();
    },
    mounted: function(){
        //this.loadEvents();
        //gsap.from('.mobile-nav-contain', {opacity: 0, left:"-400px", duration: 1});
        //alert(screen.width);
        window.addEventListener("resize", function(){
            gsap.to('.mobile-nav-contain',{opacity: 0, top:"-375px", duration: 1});
            this.mobileNav = 'closed';
        });
        var currentUrl = window.location.pathname;
        if(currentUrl.indexOf('/create') > 0 || currentUrl.indexOf('/edit') > 0){
            CKEDITOR.replace("product-ckeditor");
        }

    },
    updated: function(){

    },
    methods:{
        loadEvents: function(){
            var p = document.getElementsByClassName("mobile-hamburger");
            for(var i=0; i<p.length; i++){
                p[i].onclick = function(){
                    this.gsapMobileNav("open");
                }
            }
        },
        getTest: function(){
            if(!sessionStorage.test){
                sessionStorage.test = "Testing";
            }
            return sessionStorage.test;
        },
        displayMobileNav: function(){
            alert('Display Nav!');
        },
        gsapMobileNav: function(dir){
            if(this.mobileNav == 'closed') {
                gsap.to('.mobile-nav-contain',{opacity: 1, top:"80px", duration: 1});
                this.mobileNav = 'opened';
            }else{
                //gsap.fromTo('.mobile-nav-contain',{opacity: 1 , x:0 }, {opacity: 0 , x:-400 , duration: 1 });
                gsap.to('.mobile-nav-contain',{opacity: 0, top:"-375px", duration: 1});
                this.mobileNav = 'closed';
            }
        },
        checkInventory: function(){
            //alert('here');
        },
        social: function (outlet) {
            switch(outlet){
                case 'facebook':
                    window.open("https://www.facebook.com/profile.php?id=100086883777206");
                    break;
                case 'instagram':
                    window.open("https://www.instagram.com/modernwomansoap/");
                    break;
            }
        }
    },
});
