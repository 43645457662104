<template>
        <section>
            <div class="short-contain">
                <div id="finder_area">
                    <div id="get_started" v-if="get_started">
                        <p>
                            There are a lot of soaps to choose from. And you may be wondering what soap is best for you. We've put together this little app to help you make that decision.
                            Just answer a few questions to help us make some suggestions that we feel may be good choices for you.
                        </p>
                        <a href="#" class="finder-button" v-on:click.prevent="step_one = !step_one; get_started=!get_started;">{{product_button}}</a><br>
                    </div>
                    <div id="step_one" v-if="step_one">
                        <div class="question">What is your age group?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">21 or under
                                    <input type="radio" v-model="age_group" name="age_group" value="0-21">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">22 to 35
                                    <input type="radio" v-model="age_group" name="age_group" value="22-35">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">36 to 50
                                    <input type="radio" v-model="age_group" name="age_group" value="36-50">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Over 50
                                    <input type="radio" v-model="age_group" name="age_group" value="50+">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_one = !step_one; get_started=!get_started;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_one = !step_one; step_two=!step_two;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_two" v-if="step_two">
                        <div class="question">What is your skin tone (complextion)?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">Light
                                    <input type="radio" v-model="skin_tone" name="skin_tone" value="light">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Tan
                                    <input type="radio" v-model="skin_tone" name="skin_tone" value="tan">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Dark Tan
                                    <input type="radio" v-model="skin_tone" name="skin_tone" value="dark_tan">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Dark
                                    <input type="radio" v-model="skin_tone" name="skin_tone" value="dark">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_two = !step_two; step_one=!step_one;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_three = !step_three; step_two=!step_two;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_three" v-if="step_three">
                        <div class="question">How easily irritated is your skin?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">Never
                                    <input type="radio" v-model="irritated" name="irritated" value="never">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Sometimes
                                    <input type="radio" v-model="irritated" name="irritated" value="sometimes">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Often
                                    <input type="radio" v-model="irritated" name="irritated" value="often">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Always
                                    <input type="radio" v-model="irritated" name="irritated" value="always">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_two = !step_two; step_three=!step_three;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_four = !step_four; step_three=!step_three;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_four" v-if="step_four">
                        <div class="question">How dry is your skin, usually?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">Never Dry
                                    <input type="radio" v-model="dry" name="dry" value="never">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Sometimes Dry
                                    <input type="radio" v-model="dry" name="dry" value="sometimes">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Often Dry
                                    <input type="radio" v-model="dry" name="dry" value="often">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Always Dry
                                    <input type="radio" v-model="dry" name="dry" value="always">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_three = !step_three; step_four=!step_four;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_five = !step_five; step_four=!step_four;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_five" v-if="step_five">
                        <div class="question">How oily is your skin, usually?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">Never
                                    <input type="radio" v-model="oily" name="oily" value="never">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Sometimes
                                    <input type="radio" v-model="oily" name="oily" value="sometimes">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Often
                                    <input type="radio" v-model="oily" name="oily" value="often">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Always
                                    <input type="radio" v-model="oily" name="oily" value="always">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_four = !step_four; step_five=!step_five;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_six = !step_six; step_five=!step_five;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_six" v-if="step_six">
                        <div class="question">How consistent is your skin?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">Same all over
                                    <input type="radio" v-model="combination" name="combination" value="same">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Mostly the same
                                    <input type="radio" v-model="combination" name="combination" value="mostly">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Some different areas
                                    <input type="radio" v-model="combination" name="combination" value="some">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Different all over
                                    <input type="radio" v-model="combination" name="combination" value="different">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_six = !step_six; step_five=!step_five;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_seven = !step_seven; step_six=!step_six;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_seven" v-if="step_seven">
                        <div class="question">How problematic is your skin?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">Not Very
                                    <input type="radio" v-model="problematic" name="problematic" value="not">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Sometimes problematic
                                    <input type="radio" v-model="problematic" name="problematic" value="sometimes">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">I often have skin issues
                                    <input type="radio" v-model="problematic" name="problematic" value="often">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">My skin is an issue
                                    <input type="radio" v-model="problematic" name="problematic" value="always">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_six = !step_six; step_seven=!step_seven;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_eight = !step_eight; step_seven=!step_seven;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_eight" v-if="step_eight">
                        <div class="question">Do you have allergies?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">No
                                    <input type="radio" v-model="allergic" name="allergic" value="never">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Occationally I have reactions
                                    <input type="radio" v-model="allergic" name="allergic" value="sometimes">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">I often have reactions
                                    <input type="radio" v-model="allergic" name="allergic" value="often">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">I'm alergic to everything
                                    <input type="radio" v-model="allergic" name="allergic" value="always">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_seven = !step_seven; step_eight=!step_eight;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_nine = !step_nine; step_eight=!step_eight;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="step_nine" v-if="step_nine">
                        <div class="question">How important is it that your soap is animal free?</div>
                        <div class="row finder-selections">
                            <div class="col"></div>
                            <div class="col">
                                <label class="container">Not important
                                    <input type="radio" v-model="vegan" name="vegan" value="not">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Somewhat
                                    <input type="radio" v-model="vegan" name="vegan" value="somewhat">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">I care a lot
                                    <input type="radio" v-model="vegan" name="vegan" value="care">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">I am vegan
                                    <input type="radio" v-model="vegan" name="vegan" value="vegan">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="step_nine = !step_nine; step_eight=!step_eight;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="final_step = !final_step; step_nine=!step_nine;">Next</a>
                            </div>
                        </div>
                    </div>
                    <div id="final_step" v-if="final_step">
                        <div class="question">Review Your Answers</div>
                        <div class="row">
                            <div class="col">
                                <div id="review_answers"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="final_step = !final_step; step_eight=!step_eight;">Back</a>
                            </div>
                            <div class="col">
                                <a href="#" class="finder-button" v-on:click.prevent="findSoap">Submit</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
</template>

<script>
export default {
    data: function () {
        return {
            selection: '',
            product_button: "Let's Get Started",
            get_started: true,
            step_one: false,
            step_two: false,
            step_three: false,
            step_four: false,
            step_five: false,
            step_six: false,
            step_seven: false,
            step_eight: false,
            step_nine: false,
            final_step: false,
            age_group: '22-35',
            skin_tone: 'tan',
            irritated: 'sometimes',
            dry:'sometimes',
            oily:'sometimes',
            combination:'mostly',
            problematic:'sometimes',
            allergic: 'sometimes',
            vegan: 'not',
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
    },
    watch:{
        age_group(new_age_group) {
            sessionStorage.setItem("age_group", new_age_group);
        },
        skin_tone(new_skin_tone) {
            sessionStorage.setItem("skin_tone", new_skin_tone);
        },
        irritated(new_irritated) {
            sessionStorage.setItem("irritated", new_irritated);
        },
        dry(new_dry) {
            sessionStorage.setItem("dry", new_dry);
        },
        oily(new_oily) {
            sessionStorage.setItem("oily", new_oily);
        },
        combination(new_combination) {
            sessionStorage.setItem("combination", new_combination);
        },
        problematic(new_problematic) {
            sessionStorage.setItem("problematic", new_problematic);
        },
        allergic(new_allergic) {
            sessionStorage.setItem("allergic", new_allergic);
        },
        vegan(new_vegan) {
            sessionStorage.setItem("vegan", new_vegan);
        }
    },
    created:function(){
        //console.log("Created");
    },
    mounted: function() {
        if (sessionStorage.getItem('age_group')) {
            this.age_group = sessionStorage.getItem('age_group');
        }
        if (sessionStorage.getItem('skin_tone')) {
            this.skin_tone = sessionStorage.getItem('skin_tone');
        }
        if (sessionStorage.getItem('irritated')) {
            this.irritated = sessionStorage.getItem('irritated');
        }
        if (sessionStorage.getItem('dry')) {
            this.dry = sessionStorage.getItem('dry');
        }
        if (sessionStorage.getItem('oily')) {
            this.oily = sessionStorage.getItem('oily');
        }
        if (sessionStorage.getItem('combination')) {
            this.combination = sessionStorage.getItem('combination');
        }
        if (sessionStorage.getItem('problematic')) {
            this.problematic = sessionStorage.getItem('problematic');
        }
        if (sessionStorage.getItem('allergic')) {
            this.allergic = sessionStorage.getItem('allergic');
        }
        if (sessionStorage.getItem('vegan')) {
            this.allergic = sessionStorage.getItem('vegan');
        }
    },
    updated: function(){
        console.log("Age Group "+this.age_group);
        //alert(this.skin_tone+"/"+sessionStorage.getItem('skin_tone'));
        if(this.final_step){
            switch(this.age_group){
                case '0-21':
                    var age_group = '21 or under';
                    break;
                case '36-50':
                    var age_group = '36-50';
                    break;
                case '50+':
                    var age_group = '50+';
                    break;
                default:
                    var age_group = '22-35';
                    break;
            }
            switch(this.skin_tone){
                case 'light':
                    var skin_tone = 'Light complextion';
                    break;
                case 'dark_tan':
                    var skin_tone = 'Dark tan complextion';
                    break;
                case 'dark':
                    var skin_tone = 'Dark complextion';
                    break;
                default:
                    var skin_tone = 'Tan complextion';
                    break;
            }
            switch(this.irritated){
                case 'never':
                    var irritated = 'Never';
                    break;
                case 'always':
                    var irritated = 'Always';
                    break;
                case 'often':
                    var irritated = 'Often';
                    break;
                default:
                    var irritated = 'Sometimes';
                    break;
            }
            switch(this.irritated){
                case 'never':
                    var irritated = 'Never';
                    break;
                case 'always':
                    var irritated = 'Always';
                    break;
                case 'often':
                    var irritated = 'Often';
                    break;
                default:
                    var irritated = 'Sometimes';
                    break;
            }
            switch(this.dry){
                case 'never':
                    var dry = 'Never';
                    break;
                case 'always':
                    var dry = 'Always';
                    break;
                case 'often':
                    var dry = 'Often';
                    break;
                default:
                    var dry = 'Sometimes';
                    break;
            }
            switch(this.oily){
                case 'never':
                    var oily = 'Never';
                    break;
                case 'always':
                    var oily = 'Always';
                    break;
                case 'often':
                    var oily = 'Often';
                    break;
                default:
                    var oily = 'Sometimes';
                    break;
            }
            switch(this.combination){
                case 'same':
                    var combination = 'Same all over';
                    break;
                case 'some':
                    var combination = 'Some areas are differnt';
                    break;
                case 'different':
                    var combination = 'Different all over';
                    break;
                default:
                    var combination = 'Mostly the same';
                    break;
            }
            switch(this.problematic){
                case 'not':
                    var problematic = 'Not very';
                    break;
                case 'sometimes':
                    var problematic = 'Sometimes';
                    break;
                case 'often':
                    var problematic = 'Often';
                    break;
                default:
                    var problematic = 'Always';
                    break;
            }
            switch(this.allergic){
                case 'never':
                    var allergic = 'No';
                    break;
                case 'sometimes':
                    var allergic = 'Sometimes';
                    break;
                case 'often':
                    var allergic = 'Often';
                    break;
                default:
                    var allergic = 'Always';
                    break;
            }
            switch(this.vegan){
                case 'not':
                    var vegan = 'Not important';
                    break;
                case 'somewhat':
                    var vegan = 'Somewhat important';
                    break;
                case 'care':
                    var vegan = 'I care a lot';
                    break;
                default:
                    var vegan = 'I am vegan';
                    break;
            }
            let review_html = 'What is your age group? '+age_group+'<br>';
            review_html+= 'What is your skin tone (complextion)? '+skin_tone+'<br>';
            review_html+= 'How easily irritated is your skin? '+irritated+'<br>';
            review_html+= 'How dry is your skin, usually? '+dry+'<br>';
            review_html+= 'How oily is your skin, usually? '+oily+'<br>';
            review_html+= 'How consistent is your skin? '+combination+'<br>';
            review_html+= 'How problematic is your skin? '+problematic+'<br>';
            review_html+= 'Do you have allergies? '+allergic+'<br>';
                review_html+= 'How important is it that your soap is animal free? '+vegan+'<br>';
            document.getElementById('review_answers').innerHTML = review_html;
        }
    },
    methods:{
        findSoap: function(){
            let sessionID = document.getElementById('sessionID').value;
            let age_group = this.age_group;
            let skin_tone = this.skin_tone;
            let irritated = this.irritated;
            let dry = this.dry;
            let oily = this.oily;
            let combination = this.combination;
            let problematic = this.problematic;
            let allergic = this.allergic;
            let vegan = this.vegan;
            let csrf = this.csrf;
            let url = '/soap-finder-results';
            fetch(url, {
                method: 'post',
                headers: {
                    "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
                    'X-CSRF-TOKEN': csrf
                },
                //body: 'foo=bar&lorem=ipsum'
                body: 'sessionID='+sessionID+'&age_group='+age_group+'&skin_tone='+skin_tone+'&irritated='+irritated+'&dry='+dry+'&oily='+oily+'&combination='+combination+'&problematic='+problematic+'&allergic='+allergic+'&vegan='+vegan
            })
                .then(function (response) {
                    // The API call was successful!
                    return response.text();
                })
                .then(function (html) {
                    // This is the HTML from our response as a text string
                    console.log(html);
                    document.getElementById('final_step').innerHTML = html;
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });
        }
    }
}
</script>

