<template>
    <div class="hero-contain">
        <div class="slideshow">
            <div class="slide hero-home-1" style="display: inline;">
                <div class="content-contain">
                    <div class="slogan slogan-left slogan-text-black">
                        Let's Talk Soap<br>
                        <span class="slogan-explain">"Store bought soaps are actually <a href="https://www.fda.gov/cosmetics/cosmetic-products/frequently-asked-questions-soap" target="_blank">detergents"</a></span>
                    </div>
                </div>
            </div>
            <!--div class="slide hero-home-5" style="display: inline;">
                <div class="content-contain">
                    <div class="slogan slogan-left slogan-text-dark">
                        Limited Edition Soap!<br>
                        <span class="slogan-explain">"Get your, <a href="/all-natural-bar-soap/purple-haze-bar-soap" class="slogan-link">Purple Haze</a>, soap before supplies run out!"</span>
                    </div>
                </div>
            </div-->
            <div class="slide hero-home-2">
                <div class="content-contain">
                    <div class="slogan slogan-right slogan-text-black">
                        Modern Woman Use Natural Products<br>
                        <span class="slogan-explain">All natural soap made from all natural ingredients</span>
                        <!--span class="slogan-explain">"Use <a href="/handmade-bar-soap-finder" class="slogan-link">our soap finder</a> to find the soap specifically made for you"</span-->
                    </div>
                </div>
            </div>
            <div class="slide hero-home-3">
                <div class="content-contain">
                    <div class="slogan slogan-left">
                        Soap Specifically for your Skin<br>
                        <span class="slogan-explain">Take care of your skin now or you'll regret it later. <a href="/handmade-bar-soap-finder">Find the best soap for you</a></span>
                        <!--span class="slogan-explain">"Modern Men Don't Use Soap <a href="https://www.reuters.com/article/us-health-parabens-male-fertility/common-chemicals-in-cosmetics-soaps-tied-to-poor-semen-quality-idUSKCN1AX2C0" class="slogan-link" target="_blank">That Lowers Testosterone</a>"</span-->
                    </div>
                </div>
            </div>
            <div class="slide hero-home-4">
                <div class="content-contain">
                    <div class="slogan slogan-left">
                        Modern Women Are Not Ordinary<br>
                        <span class="slogan-explain">"A Modern Woman doesn't use the same soap ordinary women use"</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-hero hero-home-mobile">
            <div class="content-contain">
                <div class="slogan slogan-left">
                    Modern Women Are Not Ordinary<br>
                    <span class="slogan-explain">"A Modern Woman doesn't use the same soap ordinary women use"</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            customerHead: "Customer Information",
            f: 1000,
            s: 1000,
            d: 5000,
            n: document.getElementsByClassName('slide').length
        }
    },
    created:function(){
    },
    mounted() {
        console.log('Hero Component Mounted.');
        window.addEventListener("resize", function(){
            this.heroKey += 1;
        });
        if(screen.width > 1000){
            //this.slide = document.getElementsByClassName("slide");
            //this.n = this.slide.length; //number of slides
            //this.w = 100; // slide width
            //this.c = 100; // container width
            //this.ss = this.n * this.w; // slideshow width
            this.fadeInOut();
        }
    },
    methods:{
        fadeInOut: function(){
            var i = 0;
            var f = 1500;
            var d = 8000;
            var n = document.getElementsByClassName('slide').length;
            jQuery('.slide').eq(i).show();
            setInterval(function() {
                jQuery('.slide').eq(i).fadeOut(f);
                if (i == n - 1) {
                    i = 0;
                } else {
                    i++;
                }
                jQuery('.slide').eq(i).fadeIn(f);

            }, d);
        },
    }
}
</script>
